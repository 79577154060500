import { lazy, Suspense } from "react";
import {
  Container,
  CssBaseline,
  ThemeProvider,
  createTheme,
  IconButton,
  Box,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { Brightness4, Brightness7 } from "@mui/icons-material";
import { useState, useMemo, useEffect } from "react";

const PositionsTable = lazy(() =>
  import("./features/positions/PositionsTable").then((module) => ({
    default: module.PositionsTable,
  }))
);

export function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<"light" | "dark">(prefersDarkMode ? "dark" : "light");

  // Update mode when system preference changes
  useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
  }, [prefersDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            main: "#1976d2",
          },
          background: {
            default: mode === "light" ? "#f5f5f5" : "#121212",
            paper: mode === "light" ? "#ffffff" : "#1e1e1e",
          },
          text: {
            primary: mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
            secondary: mode === "light" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.7)",
          },
        },
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              ":root": {
                colorScheme: mode,
              },
              body: {
                backgroundColor: mode === "light" ? "#f5f5f5" : "#121212",
                color: mode === "light" ? "rgba(0, 0, 0, 0.87)" : "#fff",
                colorScheme: mode,
                scrollbarColor:
                  mode === "dark"
                    ? "rgba(255, 255, 255, 0.3) rgba(0, 0, 0, 0.2)"
                    : "rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.05)",
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: mode === "light" ? "#ffffff" : "#1e1e1e",
              },
            },
          },
        },
      }),
    [mode]
  );

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <h1>Positions</h1>
          <IconButton
            onClick={toggleColorMode}
            color="inherit"
            sx={{ ml: 2 }}
            aria-label={`Switch to ${mode === "light" ? "dark" : "light"} mode`}
          >
            {mode === "dark" ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        </Box>
        <Suspense fallback={<CircularProgress />}>
          <PositionsTable />
        </Suspense>
      </Container>
    </ThemeProvider>
  );
}
